import { getCustomerOptions } from '@/api/customer'

const state = () => ({
    customers: [],
})

const actions = {
    getCustomerOptions({ commit }, params) {
        return new Promise(resolve => {
            getCustomerOptions(params)
                .then(resp => {
                    commit('setCustomers', resp)

                    resolve()
                })
        })
    },
}

const mutations = {
    setCustomers: (state, customers) => {
        state.customers = customers
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}
